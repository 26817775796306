// @ts-check
import React, { useMemo } from 'react';
import { useAdmin } from 'hooks';
import { getCalendarSettings } from 'constants/calendarSettings';
import Day from './Calendar/components/Day';
import WeekdayName from './Calendar/components/WeekdayName';
import useRangeDays from './Calendar/hooks/useRangeDays';

const Calendar = ({ shape, onClick }) => {
  const rangeDays = useRangeDays(shape.calendarType);
  const calendarSettings = useMemo(() => getCalendarSettings(shape), [shape]);
  const admin = useAdmin();
  return (
    <g className={`calendar${shape.id}`} transform={`translate(${shape.x} ${shape.y}) rotate(${shape.rotation || 0})`}>
      <svg
        width={shape.width}
        height={shape.height}
        style={{ pointerEvents: admin ? 'auto' : 'none' }}
        onClick={admin ? onClick : null}
        viewBox={`0 0 ${calendarSettings.CalendarShape.width} ${calendarSettings.CalendarShape.height}`}
      >
        <rect fill={shape.backgroundColor} x="0" y="0" width="100%" height="100%" />
        <rect
          x={calendarSettings.CalendarShape.paddingLeft}
          y={calendarSettings.CalendarShape.paddingTop}
          width={
            calendarSettings.CalendarShape.width -
            calendarSettings.CalendarShape.paddingLeft -
            calendarSettings.CalendarShape.paddingRight
          }
          height={
            calendarSettings.CalendarShape.height -
            calendarSettings.CalendarShape.paddingTop -
            calendarSettings.CalendarShape.paddingBottom
          }
          fillOpacity="0"
          stroke="grey"
          strokeOpacity="0.5"
          strokeWidth="1"
          strokeDasharray="5,5"
        />
        <g
          transform={`translate(${calendarSettings.DaysContainerShape.marginLeft} ${calendarSettings.DaysContainerShape.marginTop})`}
        >
          {rangeDays.slice(0, calendarSettings.NumberDaysInRowAndColumn.column).map((day, index) => (
            <WeekdayName shape={shape} day={day} index={index} />
          ))}
          <g transform={`translate(0 ${calendarSettings.DayShape.height})`}>
            {rangeDays.map((day, index) => {
              return <Day index={index} day={day} shape={shape} />;
            })}
          </g>
        </g>
      </svg>
    </g>
  );
};

export default Calendar;
